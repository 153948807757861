import React from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import BreadCrumb from '../components/BreadCrumb';
import MaterialIcon, { colorPalette } from 'material-icons-react';
import ContactForm from "../components/ContactForm";

const ContactUs = () => {
    return (
        <div>
            <Navbar />
            <div class="container">
                <BreadCrumb active_page="ContactUs" />
                <div class="inner-container">
                    <section class="contact" id="contact">
                        <div class="container">
                            <h2>Our Work Speaks For Us</h2>
                            <p class="contact-quote">Do You Have A Project That You Would Like To Discuss On? Talk To Our Expert</p>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="email">
                                                <MaterialIcon icon="mail" size={40} color={"#0d53ad"} />
                                                <h3>Email</h3>
                                                <p><a href="mailto:info@krisantec.com" className="anchor-text">info@krisantec.com</a></p>
                                                <br />

                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="phone">
                                                <MaterialIcon icon="phone_in_talk" size={40} color={"#0d53ad"} />
                                                <h3>Call</h3>
                                                <p><a href={`tel:+91 8712874770`} className="anchor-text">+91 8712874770</a></p>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="address mb-5" style={{padding:'24px'}}>
                                        <MaterialIcon icon="pin_drop" size={40} color={"#0d53ad"} />
                                        <h3>Address</h3>
                                        <p>Suraj Mansion, #202, Shyam Karan Rd, Leelanagar, Hyderabad, Telangana 500016</p>
                                        <button type="submit" class="btn btn-primary"><a href="https://www.google.com/maps/dir/?api=1&destination=17.436851691041547,78.4529135003686&travelmode=driving" target="_blank" style={{ color: '#ffffff', textDecoration: 'none' }}>Get Directions</a></button>
                                        <br />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="box-with-shadow">
                                        <h2>Submit Your Query</h2>
                                        {/* <form action="">
                                                <div class="mb-3 mt-3">
                                                    <label for="name">Name:</label>
                                                    <input type="name" class="form-control" id="name" placeholder="Enter Name" name="name" />
                                                </div>
                                                <div class="mb-3 mt-3">
                                                    <label for="contact">Contact No:</label>
                                                    <input type="contact" class="form-control" id="contact" placeholder="Enter Contact No "
                                                        name="contact" />
                                                </div>
                                                <div class="mb-3 mt-3">
                                                    <label for="email">Email:</label>
                                                    <input type="email" class="form-control" id="email" placeholder="Enter Email" name="email" />
                                                </div>
                                                <div class="mb-3 mt-3">
                                                    <label for="query">Query:</label>
                                                    <textarea class="form-control" rows="2" id="query" name="query"></textarea>
                                                </div>
                                                <button type="submit" class="btn btn-primary">Submit</button>
                                            </form> */}
                                        <ContactForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ContactUs;