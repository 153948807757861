import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { Link, useNavigate, useLocation } from 'react-router-dom';
const ProductDevelopment = () => {
    const location = useLocation();
    const { sub_page, title, url } = location.state || {};
    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="Product Development"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                    sub_page={sub_page ? {
                        'link': url,
                        'text': title
                    } : null}
                />
            </section>
            <section className="ecosystem-card-main-embrace">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ecosystem-card" style={{ padding: "30px 56px", maxWidth: "542px" }}>
                                <h5>Embrace Innovation</h5>
                                <p>Our product development engineers would guide you on how you can enhance your business with innovative technologies and stay ahead of the competition.</p>
                            </div>
                            <div className="ecosystem-card" style={{ padding: "30px 56px", maxWidth: "542px" }}>
                                <h5>Transform Design To Digital Product</h5>
                                <p>We are an amalgamate of designers, developers, testers, and marketing professionals under one roof. This creates a comprehensive way to launch products swifty and cost effectively.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="image-right">
                                <img src={require('../../assets/images/services/Embrace.png')} alt="action oriented" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="Amplify">
                <h2>We’ll Amplify Your Product Value</h2>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card_1">
                                <div className="one">01</div>
                                <div className="card_1_detail">
                                    <h5>Ideation</h5>
                                    <p>Our team analyzes the problem your project is trying to solve and check if its user centered and in line with all its stakeholders</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card_1">
                                <div className="one">02</div>
                                <div className="card_1_detail">
                                    <h5>Detailing</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card_1">
                                <div className="one">03</div>
                                <div className="card_1_detail">
                                    <h5>Manifestation</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card_1">
                                <div className="one">04</div>
                                <div className="card_1_detail">
                                    <h5>Validate & Support</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="newproject">
                <div className="ellipse_top" style={{ backgroundColor: 'white' }}></div>
                <div class="container">
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default ProductDevelopment;