import React, { useState } from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { Link, useNavigate } from 'react-router-dom';
import './BusinessProcess.css';
import { FaArrowRightLong } from "react-icons/fa6";
import { AiFillPlusCircle } from "react-icons/ai";

const BusinessProcess = () => {
    const navigate = useNavigate();
    const handleClick = (url) => {
        navigate(url);
    }
    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="Business Process"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                />
            </section>
            <section className="processes">
                <div className="container">
                    <div className="process_grid_3">
                        <div className="item">
                            <div className="process_card">
                                <h3>ERP Automation</h3>
                                <p>An all in one platform that would automate any aspect of your business process be it finance, inventory, supply chain, sales, purchasing, human resources, customer relationship management, & so on.<br />Expand globally, run operations with ease using our integrated unified platform that seamlessly automates complex process of your enterprise.</p>
                                <span className="read-more" onClick={() => handleClick('erp-automation')}>
                                    Read More <FaArrowRightLong size={20} style={{ marginLeft: '8px' }} />
                                </span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="process_card">
                                <h3>Hospital Information System</h3>
                                <p>Simplify workflow patterns of your hospital by digitizing the complete operations and increase the overall satisfaction of the patient</p>
                                <span className="read-more" onClick={() => handleClick('hospital-information-system')}>
                                    Read More <FaArrowRightLong size={20} style={{ marginLeft: '8px' }} />
                                </span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="process_card">
                                <h3>Human Resource Management Information System</h3>
                                <p>Standardize your HR tasks and processes and offer a more seamless, streamlined, and efficient coordination between employees and the organization.</p>
                                <span className="read-more" onClick={() => handleClick('human-resource-management-information-system')}>
                                    Read More <FaArrowRightLong size={20} style={{ marginLeft: '8px' }} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sales">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 left">
                            <img src={require('../../assets/images/services/sales_img.webp')} className="img-fluid"/>
                        </div>
                        <div className="col-md-6">
                            <div className="card-auto">
                                <h3 className="text-center">Sales Process Automation</h3>
                                <p>
                                    Eliminate manual tedious tasks,
                                    streamline recurring process, and
                                    improves your sales force efficiency by
                                    automating tasks, workflows, processes,
                                    customer journey, campaigns, & so on. Manage
                                    all your products, processes, and teams from
                                    a single unified platform
                                </p>
                                <div className="text-center">
                                    <button className="btn btn-primary" onClick={() => handleClick('sales-process-automation')}>View More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ paddingBottom: '0px' }}>
                <div className="container">
                    <div className="process_grid_3">
                        <div className="card_item">
                            <div className="img-container">
                                <img src={require('../../assets/images/services/billing.png')} />
                            </div>
                            <div className="card_body">
                                <div>
                                    <h3>Billing & Accounts Process Automation</h3>
                                    <p>
                                        Don’t allow your current manual process to increase the risk of financial leaks. Centralise your billing and payment process from invoice creation to payment collection and get paid faster.Automation makes it easier to track the organizational financial perform -ance and enables financial planning.
                                    </p>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-primary" onClick={() => handleClick('billing-accounts-process-automation')}>View More</button>
                                </div>
                            </div>
                        </div>
                        <div className="card_item">
                            <div className="img-container">
                                <img src={require('../../assets/images/services/shopping.png')} />
                            </div>
                            <div className="card_body">
                                <div>
                                    <h3>Shopping Cart</h3>
                                    <p>
                                        Build a robust store in line with your niche. We possess ingenious expertise to develop solutions that precisely reflect your business needs. Made using the state of art technologies we offer seamless navigation to users
                                    </p>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-primary" onClick={() => handleClick('shopping-cart')}>View More</button>
                                </div>
                            </div>
                        </div>
                        <div className="card_item">
                            <div className="img-container">
                                <img src={require('../../assets/images/services/supply.png')} />
                            </div>
                            <div className="card_body">
                                <div>
                                    <h3>Supply Chain Process</h3>
                                    <p>
                                        We tailor your supply chain strategy to meet your needs. Optimize procurement management, enhance process transparency while saving on time and resources.
                                    </p>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-primary" onClick={() => handleClick('supply-chain-process')}>View More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="process_bottom">
                <div className="responsive_img">
                    <img src={require('../../assets/images/services/bp_1.png')} />
                </div>
                <div className="half">
                    <div className="plus_card">
                        <h3>Job Portal</h3>
                        <p>Simplify your job search with the finest designed job portals. We make it easier for your professionals & recruitment team to interact with one another and help you hire the best fit.</p>
                        <AiFillPlusCircle className="icon" size={68} onClick={() => handleClick('job-portal')}/>
                    </div>
                    <div className="plus_card">
                        <h3>Location Based Services</h3>
                        <p>
                            We use real time geo location data to provide information
                            or extend services to the users. Our solutions integrate
                            data from any kind of resources be it GPS, QR codes
                            and RFID technology
                        </p>
                        <AiFillPlusCircle className="icon" size={68} onClick={() => handleClick('location-based-services')}/>
                    </div>
                </div>
                <div className="responsive_img">
                    <img src={require('../../assets/images/services/bp_2.png')} />
                </div>
                <div className="half" style={{alignItems: 'flex-start'}}>
                    <div className="plus_card">
                        <h3>Event Management & Webinars</h3>
                        <p>
                            Our solutions deliver not just a virtual learning experience but a real interaction among learners. You can facilitate discussions using our virtual tools like whiteboard,
                            breakout rooms, chatbox, polling, Q & A session.
                        </p>
                        <AiFillPlusCircle className="icon" size={68} onClick={() => handleClick('event-management-webinars')}/>
                    </div>
                    <div className="plus_card">
                        <h3>Social Networking</h3>
                        <p>
                            Get the best social network development services for your
                            business fully customized to your niche in line with techno
                            -logy standards. We incorpate technology mediated social
                            interactions to our development practices, and techniques
                            thereby adding a social dimension to customer interactions.
                        </p>
                        <AiFillPlusCircle className="icon" size={68} onClick={() => handleClick('social-networking')}/>
                    </div>
                </div>
            </section>
            <section className="newproject">
                <div className="ellipse_top" style={{ backgroundColor: 'white' }}></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default BusinessProcess;