import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { Link, useNavigate,useLocation } from 'react-router-dom';
import '../About.css';
import { FiArrowRight } from "react-icons/fi";
import { FaAnglesRight } from "react-icons/fa6";
const ApplicationDevelopment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { sub_page,title,url } = location.state || {};
    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="Web Design & Development"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                    sub_page={sub_page ? {
                        'link': url,
                        'text': title
                    } : null}

                />
            </section>
            <section className="ecosystem-card-main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="ecosystem-card" style={{ padding: "30px 56px",maxWidth:"542px" }}>
                                <h5>Transform Your Brand Into Visual Story</h5>
                                <p>Web design is the first impression that you make with your potential customer. A centralized channel for all kinds of marketing activities. Make it alluring, functional, & dynamic</p>
                            </div>
                            <div className="ecosystem-card" style={{ padding: "30px 56px",maxWidth:"542px" }}>
                                <h5>We Design Experiences</h5>
                                <p>Web design is all about experiences that enhance the brand value. We design experiences that help you communicate with the users.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="image-right">
                                <img src={require('../../assets/images/services/WebBackground.png')} alt="action oriented" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="process">
                <h2>Services</h2>
                <div className="container">
                    <div className="grid_container_flex">
                        <div className="grid-item">Web Designing</div>
                        <div className="grid-item">Website Redesigning</div>
                        <div className="grid-item">Domain Registration</div>
                    </div>
                    <div className="grid_container_flex">
                        <div className="grid-item">Web Hosting</div>
                        <div className="grid-item">Content Management Services</div>
                        <div className="grid-item">Payment Gateway Integration Services</div>
                    </div>
                </div>
            </section>
            <section className="rodamap">
                <div className="container">
                    <div>
                    <h3>Roadmap To Development</h3>
                    <h4>UX Research</h4>
                    <p>Data driven insights for design strategy</p>
                    </div>
                    <div className="flex-item">
                    <div className="card">
                       <div className="image-card-wed">
                       <img src={require('../../assets/images/services/DetermineUX.png')} alt="DetermineUX" />
                       </div>
                       <h4>Determine UX metrics</h4>
                    </div>
                    <div className="card">
                        <div className="image-card-wed">
                    <img src={require('../../assets/images/services/Scalability.png')} alt="Scalability" />
                    </div>
                    <h4>Consider social
                    implications </h4>
                    </div>
                    <div className="card">
                    <div className="image-card-wed">
                    <img src={require('../../assets/images/services/Consider.png')} alt="Consider" />
                    </div>
                    <h4>Scalability prospect</h4>
                    </div>
                </div>
                </div>
            </section>
            <section className="process">
                <div className="header">
                <h2 style={{marginBottom:"8px"}}>UX/UI Design</h2>
                <p>Adopting a product and design functionality that aligns with client objectives and end user behaviour</p>
                </div>
                <div className="container">
                    <div className="grid_container_flex">
                        <div className="grid-item">User Journey</div>
                        <div className="grid-item">Wireframes</div>
                        <div className="grid-item">Prototypes</div>
                    </div>
                </div>
            </section>
            <section className="develop">
                <h2>Develop</h2>
                <p>Translate the design into an innovative product for use across all platforms</p>
                <div className="container">
                <div className="flex-item">
                    <div>
                    <div className="card">
                    <img src={require('../../assets/images/services/FrontEnd.png')} alt="FrontEnd" />
                    </div>
                    <h4>Determine UX metrics</h4>
                    </div>
                    <div>
                    <div className="card">
                    <img src={require('../../assets/images/services/WebApp.png')} alt="WebApp" />
                    </div>
                    <h4>Consider social
                    implications </h4>
                    </div>
                    <div>
                    <div className="card">
                    <img src={require('../../assets/images/services/MobileApp.png')} alt="MobileApp" />
                    </div>
                    <h4>Mobile Application</h4>
                    </div>
                </div>
                </div>
            </section>
            <section className="newproject">
                <div className="ellipse_top"></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default ApplicationDevelopment;