import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { Link } from "react-router-dom";
const RetailSoftwareSystem = () => {
    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="Retail Software System"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                    sub_page={{
                        'link': '/services/business-domains',
                        'text': 'Business Domains'
                    }}
                />
            </section>
            <section className="our-services">
                <div className="container">
                    <h2>All In One</h2>
                    <p className="text-center">Our retail management software would help you sell in store, online, at marketplaces, and on the go. You can manage all aspects of your business with this easy to use system. Add warehouses, outlets, new locations at the click of the button.</p>
                    <div className="grid_container_2">
                        <div className="grid-item">
                            <span>01</span>
                            <div>
                                <h3>Inventory Management</h3>
                                <p>Track inventory levels across multiple locations, create product variants, order directly with built in purchase orders, minimize out of stock using low stock alerts.</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>02</span>
                            <div>
                                <h3>Purchase Management</h3>
                                <p>Streamline processes right from purchase of inventory, assigning orders to suppliers, maintaining purchase bills, & so on.</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>03</span>
                            <div>
                                <h3>Fast, Secure, & Integrated</h3>
                                <p>Give your customers the flexibility they crave for. Accept payments of all kinds including contactless payments. Secured payments in line with PCI compliance along with end to end encryption</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>04</span>
                            <div>
                                <h3>Analytics</h3>
                                <p>Review business analytics i.e., sales, customer profiles, orders, and overall performance through central dashboard</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>05</span>
                            <div>
                                <h3>User Control</h3>
                                <p>Manage who gets access to which part of the data from the central dashboard. This provides users access only to the data that’s deemed necessary for their roles and prevent data breaches.</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>06</span>
                            <div>
                                <h3>We’ve got your back</h3>
                                <p>We offer comprehensive 24 X 7 support, through one on one training sessions, guides, videos, and webinars.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="virtual" style={{backgroundColor: '#E6EEFB'}}>
                <div className="container">
                    <h2 className="rss-h2">Why Are We The Perfect Solution For Your Business?</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <ul className="rss blue-arrows">
                                <li>Configured To Your Needs</li>
                                <li>Real Time</li>
                                <li>Scalable</li>
                                <li>Centralized Architecture</li>
                                <li>Integrated Analytics</li>
                                <li>Mobile Friendly</li>
                                <li>Offline Billing</li>
                            </ul>
                        </div>
                        <div className="col-md-6 d-flex justify-content-start align-items-center">
                            <img src={require('../../assets/images/services/why_perfect.webp')} alt="perfect solution" className="rss-image" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="newproject">
                <div className="ellipse_top"></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default RetailSoftwareSystem;