import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';

const JobPortal = () => {
    return (
        <div>
            <Navbar />
                <div class="container">
                    <BreadCrumb 
                        active_page="Job Portal" 
                        prev_sub_page={{
                            'link':'/services',
                            'text':'Services'
                        }}
                        sub_page={{
                            'link':'/services/business-process',
                            'text':'Business Process'
                        }} 
                    />
                    <div class="inner-container">
                        <h2>Job Portal</h2>
                        <h3>Change The Way You Function</h3>
                        <p>Simplify your job search with the finest designed job portals. We make it easier for your professionals and recruitment team to interact with one another and help you hire the best fit.</p>
                        <h3>3 Tier</h3>
                        <p>Recruitment process automation requires several functionalities that are integrated together. Our application is built to handle job seeker, employer, and admin functionalities through a single unified platform.</p>
                        <h3>Built With Strategy In Mind</h3>
                        <p>Our innovative cutting edge technologies create solutions that work closely with the organizational objectives to effectively build your workforce</p>
                        <h3>Process We Follow</h3>
                        <img alt="" src={require('../../assets/images/services/job-portal.png')} className="d-block m-auto mw-70" />
                        <h3>Features</h3>
                        <img alt="" src={require('../../assets/images/services/job-portal-2.png')} className="d-block m-auto mw-70" />
                        <h3>Why Choose Us</h3>
                        <ul>
                            <li>Unique Solutions</li>
                            <li>Real Time Interaction</li>
                            <li>State Of Art Filtration Tools</li>
                            <li>Proven Expertise</li>
                        </ul>
                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default JobPortal;