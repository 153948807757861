import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { Link, useNavigate } from 'react-router-dom';
import '../About.css';
import { FiArrowRight } from "react-icons/fi";

const ItDevelopment = () => {
    const navigate = useNavigate();
    const navigation = (item) => {
        navigate(item, { state: { sub_page: true,title:"IT Development & Consulting",url:"/services/it-development-consulting" } });
    }
    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="IT Development & Consulting"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                />
            </section>
            <section className="ecosystem">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={require('../../assets/images/services/ecosystem.png')} />
                            <h3>Ecosystem Analysis</h3>
                            <p>In depth analysis of IT needs, challenges, and existing IT ecosystem.No matter where you are in your journey Krisantician will guide you reach sustainability goals.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require('../../assets/images/services/technologies.png')} />
                            <h3>Technologies Redefined</h3>
                            <p>Technological plans that doesn’t interrupt existing business operations. This involves building an orchestration layer that involves people, processes, and systems for smoother integration of services.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ao">
                <img src={require('../../assets/images/services/action oriented.png')} alt="action oriented" />
            </section>
            <section className="what-we-do">
                <div className="container">
                    <h2 className="text-center">What We Do</h2>
                    <div className="grid_3">
                        <div className="card" onClick={() => navigation('/services/it-development-consulting/application-development')} style={{ cursor: 'pointer' }}>
                            <img src={require('../../assets/images/services/app-dev.png')} />
                            <h5>Application Development</h5>
                            <p>Build it right and fast. Navigate through your business challenges with latest tech trends</p>
                            <FiArrowRight size={20} style={{ marginTop: '20px', cursor: 'pointer' }} />
                        </div>
                        <div className="card" onClick={() => navigation('/services/it-development-consulting/web-design-development')} style={{ cursor: 'pointer' }}>
                            <img src={require('../../assets/images/services/webdesign.png')} />
                            <h5>Web Design & Development</h5>
                            <p>Transform Your Brand Into Visual Story. Make it alluring, functional, & dynamic</p>
                            <FiArrowRight size={20} style={{ marginTop: '20px', cursor: 'pointer' }} />
                        </div>
                        <div className="card" onClick={() => navigation('/services/it-development-consulting/erp-development')} style={{ cursor: 'pointer' }}>
                            <img src={require('../../assets/images/services/erp.png')} />
                            <h5>ERP Development</h5>
                            <p>Make Your Enterprise Future Ready. Streamline your business operations, ......</p>
                            <FiArrowRight size={20} style={{ marginTop: '20px', cursor: 'pointer' }} />
                        </div>
                        <div className="card" onClick={() => navigation('/services/it-development-consulting/product-development')} style={{ cursor: 'pointer' }}>
                            <img src={require('../../assets/images/services/proddev.png')} />
                            <h5>Product Development</h5>
                            <p>Embrace Innovation and transform the design in your mind to a digital product. Amplify .......</p>
                            <FiArrowRight size={20} style={{ marginTop: '20px', cursor: 'pointer' }} />
                        </div>
                        <div className="card" onClick={() => navigation('/services/it-development-consulting/product-maintenance')} style={{ cursor: 'pointer' }}>
                            <img src={require('../../assets/images/services/prodmain.png')} />
                            <h5>Product Maintenance</h5>
                            <p>Is as important as project planning & a most overlooked factor in project success. We ......</p>
                            <FiArrowRight size={20} style={{ marginTop: '20px', cursor: 'pointer' }} />
                        </div>
                        <div className="card" onClick={() => navigation('/services/it-development-consulting/it-consulting')} style={{ cursor: 'pointer' }}>
                            <img src={require('../../assets/images/services/itcon.png')} />
                            <h5>IT Consulting</h5>
                            <p>Get Your Business Aligned. We help you digitalize operations, optimize portf.......</p>
                            <FiArrowRight size={20} style={{ marginTop: '20px', cursor: 'pointer' }} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="newproject">
                <div className="ellipse_top" style={{ backgroundColor: 'white' }}></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default ItDevelopment;