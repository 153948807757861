import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';

const HospitalInformationSystem = () => {
    return (
        <div>
            <Navbar />
                <div class="container">
                    <BreadCrumb 
                        active_page="Hospital Information System" 
                        prev_sub_page={{
                            'link':'/services',
                            'text':'Services'
                        }}
                        sub_page={{
                            'link':'/services/business-process',
                            'text':'Business Process'
                        }} 
                    />
                    <div class="inner-container">
                        <h2>Hospital Information System</h2>
                        <h3>Transform Hospital Operations</h3>
                        <p>Simplify workflow patterns of your hospital by digitizing the complete operations and increase the overall satisfaction of the patient</p>
                        <p>Built with a futuristic vision we work closely in line with our clients to design solutions that can be integrated seamlessly into the existing healthcare systems</p>
                        <h3>Built To Transform</h3>
                        <p>Ensures seamless communication across all departments from admissions, treatment, transfers, discharge, and billing.</p>
                        <h3>Core Modules</h3>
                        <img alt="" src={require('../../assets/images/services/his.png')} className="d-block m-auto mw-60" />
                        <h3>Supporting Modules</h3>
                        <img alt="" src={require('../../assets/images/services/his-2.png')} className="d-block m-auto mw-60" />
                        <div className="row mt-5">
                            <div className="col-md-6">
                                <h3>Solution Highlights</h3>
                                <ul>
                                    <li>Multi Locational</li>
                                    <li>Customizable</li>
                                    <li>Mobility</li>
                                    <li>Compliance</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <h3>Why Choose Us</h3>
                                <ul>
                                    <li>Faster Time To Market</li>
                                    <li>Secured Integrations</li>
                                    <li>Less Complexity</li>
                                    <li>Streamlined workflows</li>
                                    <li>Highly Scalable</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default HospitalInformationSystem;