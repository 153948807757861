import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';

const LocationBasedServices = () => {
    return (
        <div>
            <Navbar />
                <div class="container">
                    <BreadCrumb 
                        active_page="Location Based Services" 
                        prev_sub_page={{
                            'link':'/services',
                            'text':'Services'
                        }}
                        sub_page={{
                            'link':'/services/business-process',
                            'text':'Business Process'
                        }} 
                    />
                    <div class="inner-container">
                        <h2>Location Based Services</h2>
                        <h3>Bring Your Ideas To Life</h3>
                        <h3>We use real time geo location data to provide information or extend services to the users. Our solutions integrate data from any kind of resources be it GPS, QR codes and RFID technology      </h3>
                        <h3>How Can One Leverage It For Their Business</h3>
                        <ul>
                            <li>Localized Search</li>
                            <li>City Guides</li>
                            <li>Location Based Advertising</li>
                            <li>Route Optimization</li>
                            <li>Delivery Scheduling</li>
                            <li>Proof Of Delivery</li>
                        </ul>
                        <h3>How We Do</h3>
                        <img alt="" src={require('../../assets/images/services/scp.png')} className="d-block m-auto mw-70" />
                        <h3>Benefits</h3>
                        <ul>
                            <li>Precise & Quick</li>
                            <li>Real Time Notifications</li>
                            <li>Resource Optimisation</li>
                            <li>Workforce Management Tracking</li>
                            <li>Geofencing</li>
                        </ul>
                        <h3>Select The One That Suits Your Niche</h3>
                        <img alt="" src={require('../../assets/images/services/scp.png')} className="d-block m-auto mw-70" />
                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default LocationBasedServices;