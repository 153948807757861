import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { Link } from "react-router-dom";

const InformationTechnology = () => {
    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="Information Technology"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                    sub_page={{
                        'link': '/services/business-domains',
                        'text': 'Business Domains'
                    }}
                />
            </section>
            <section className="right-sized">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ecosystem-card" style={{ padding: "30px 56px", maxWidth: "542px" }}>
                                <h5>Right Sized</h5>
                                <p>It’s your idea and we help you do more of it. We speak your language and turn your ideas into ground breaking business solutions.</p>
                            </div>
                            <div className="ecosystem-card" style={{ padding: "30px 56px", maxWidth: "542px" }}>
                                <h5>Data Driven</h5>
                                <p>The best strategies are built using data and you cannot manage what you cannot measure. We help structure the data story using interactive custom built dashboards for informed decision making.</p>
                            </div>
                        </div>
                        <div className="col-md-6 order-first">
                            <img src={require('../../assets/images/services/right_sized.webp')} alt="right sized" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-services">
                <div className="container">
                    <h2>Our Services</h2>
                    <div className="grid_container_2">
                        <div className="grid-item">
                            <span>01</span>
                            <div>
                                <h3>Product Development</h3>
                                <p>We develop your ideas and use technologies that fit your business. Applications that conceptualize, define, realize, and support your business transformation programs.</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>02</span>
                            <div>
                                <h3>Web Design</h3>
                                <p>Make sure it makes the right impression. Engage your audience with interactive websites. We develop customizable, scintillating, SEO friendly websites that meets your evolving business needs.</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>03</span>
                            <div>
                                <h3>Web Development</h3>
                                <p>We are an expertise in all kinds of web development solutions ranging from static web pages to complex web based social network services.</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>04</span>
                            <div>
                                <h3>App Modernization</h3>
                                <p>It’s not just techology but the people behind the technology that makes the difference. Our technology roadmaps will enable seamless modernization of applications and systems.</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>05</span>
                            <div>
                                <h3>Technology Consulting</h3>
                                <p>We just don’t deploy the technology at your organization. We focus on embedding it into your business process.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="benefits">
                <div className="container">
                    <h2>Efficient Can Be Simple Too</h2>
                    <div className="grid_container_2">
                        <h3 className="grid-item">Powerful Tools</h3>
                        <h3 className="grid-item">Bespoke Workflows</h3>
                        <h3 className="grid-item">Intelligent Automation</h3>
                        <h3 className="grid-item">Realtime Reporting</h3>
                    </div>
                </div>
            </section>
            <section className="newproject">
                <div className="ellipse_top"></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default InformationTechnology;