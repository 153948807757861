import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';

const SearchEngineOptimization = () => {
    return (
        <div>
            <Navbar />
                <div class="container">
                    <BreadCrumb 
                        active_page="Search Engine Optimization" 
                        prev_sub_page={{
                            'link':'/services',
                            'text':'Services'
                        }}
                        sub_page={{
                            'link':'/services/digital-marketing',
                            'text':'Digital Marketing'
                        }} 
                    />
                    <div class="inner-container">
                        <h2>Search Engine Optimization</h2>
                        <h3 className="text-center">An Inside Look Into Wat We Do</h3>
                        <img alt="" src={require('../../assets/images/services/seo.png')} className="d-block m-auto mw-50" />
                        <h3>Site Audit</h3>
                        <p>Involves optimizing your website in line with your target audience. We would audit the navigation structure, and do a duplicate copy check.</p>
                        <p>Multivariate testing is done to determine the best possible conversion rates and the highest rankings. This is of prime importance as search engines keep changing their algorithms frequently.</p>
                        <h3>Keyword Research</h3>
                        <p>We would review the relevant keywords for your niche and lay down a competitive landscape that would boost your ranking for specific search terms.</p>
                        <h3>On Page Optimization</h3>
                        <p>Make your website stand out among your competitiors. Includes inclusion of HTML tags robots tag, canolical tags, headlines, images, title tags, & meta tag optimization, auditing site for quality content.</p>
                        <h3>Off Page Optimization</h3>
                        <h3>Position Your Site As Authority</h3>
                        <p>Off site optimizations improves your website or page ranking through activities outside the site i.e., link building, local citations, blogging, influencer marketing & more.</p>
                        <h3>Link Building</h3>
                        <p>Link building is the heart of off page optimization. We have a dedicated team who would work on acquiring quality backlinks from high authority domains</p>
                        <ol>
                            <li>Increase Ranking Signals</li>
                            <li>Acquire Referral Traffic</li>
                            <li>Gain Brand Control</li>
                        </ol>
                        <h3>Built Precisely</h3>
                        <img alt="" src={require('../../assets/images/services/seo-2.png')} className="d-block m-auto mw-50" />
                        <h3>Local SEO</h3>
                        <p>According to the industry reports it has been seen that 70% of the users call or visit the local business. We would enhance your business presence on map results and help you rank better in local listings.</p>
                        <h3>Technical SEO Solutions</h3>
                        <p>Includes identifying technical issues, crawling errors, broken pages, & other site errors. Optimizing these errors would make it easier for the search engines to crawl your website and index it efficiently.</p>
                        <ul>
                            <li>On Site Optimization</li>
                            <li>Crawlability & Site Maps</li>
                            <li>Core Web Vitals Optimization</li>
                            <li>Site Speed</li>
                            <li>Structured Data Markup</li>
                            <li>Image Optimization</li>
                        </ul>
                        <h3>Benefits</h3>
                        <ul>
                            <li>Better Organic Traffic</li>
                            <li>Positive User Experience</li>
                            <li>High Conversion rates</li>
                        </ul>
                        <h3>Activity Reports</h3>
                        <p>Includes data about your website traffic, top referring keywords, & other search engine activities. This needs to be tracked over time to detect trends and assess the impact of SEO optimization.</p>
                        <ul>
                            <li>User Engagements</li>
                            <li>Identify Search Trends</li>
                            <li>Prioritise Campaigns</li>
                            <li>Detect Traffic Drops</li>
                        </ul>
                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default SearchEngineOptimization;