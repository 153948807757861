import React, { useRef, useState, useEffect } from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { GoArrowUpRight, GoArrowDownLeft } from "react-icons/go";
import Slider from "react-slick";
import { Link } from "react-router-dom";
const HealthcareIT = () => {
    let sliderRef = useRef(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: windowWidth > 991 ? 2 : 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        // autoplay: true,
        appendDots: dots => (
            <div
                style={{
                    position: "relative"
                }}
            >
                <div style={{ position: "absolute" }}>
                    <ul style={{ margin: "0px" }} className="dm-dots"> {dots} </ul>
                </div>
            </div>
        )
    };
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "none" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "none" }}
                onClick={onClick}
            />
        );
    }
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="Healthcare IT"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                    sub_page={{
                        'link': '/services/business-domains',
                        'text': 'Business Domains'
                    }}
                />
            </section>
            <section className="healthcareit">
                <div className="container">
                    <h2>Healthcare IT</h2>
                    <p>We build our solutions around your practice. Our solutions would cover every aspect of your practice, be it diagnostics, patient care, or administration. Unify all roles of your practise to improve clinical care</p>
                    <div className="row">
                        <div className="col-md-4 one">
                            <div className="card">
                                <h4>Solutions For Every Step</h4>
                                <p>Be it healthcare delivery, diagnosing with precision, tracking patient health records, data management, communication glitches we have solutions for every step of patient journey.</p>
                            </div>
                        </div>
                        <div className="col-md-4 two">
                            <div className="card">
                                <h4>Go Straight To Smart</h4>
                                <p>Our team of experienced subject matter experts and healthcare technofunctional architects will help your organization manage its health information systems through advisory services, and strategic solutions.</p>
                            </div>
                        </div>
                        <div className="col-md-4 three">
                            <div className="card">
                                <h4>Automation Is Game Changer</h4>
                                <p>Our IT solutions are core resources for both health care organisation and professionals to aid decision making to deliver utmost quality.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="who">
                <div className="container">
                    <h2>Who Can Benefit</h2>
                    <div className="grid_container_3">
                        <h3 className="grid-item">Hospitals & Medical Institutions</h3>
                        <h3 className="grid-item">Clinics</h3>
                        <h3 className="grid-item">Diagnostics</h3>
                        <h3 className="grid-item">Physicians</h3>
                        <h3 className="grid-item">Pharmacy</h3>
                        <h3 className="grid-item">Healthcare Aggregators</h3>
                    </div>
                </div>
            </section>
            <section className="hc-solutions">
                <div className="container">
                    <h2>Solutions That Reasonate With Clinical Context</h2>
                    <Slider
                        ref={slider => {
                            sliderRef = slider;
                        }}
                        {...settings}
                    >
                        <div className="dm-card">
                            <div>
                                <h5>ERP</h5>
                                <p>Tailor made, robust and customised that aligns well in line with clinical practices. One shared database that provides your organization with a clear line of sight across all departments.</p>
                            </div>
                        </div>
                        <div className="dm-card">
                            <div>
                                <h5>Telemedicine/Virtual Health</h5>
                                <p>We at Krisantec focus on developing virtual health care solutions i.e., video consultations, secured clinical documentation & sharing, booking and all kinds of clinical coordination services through a unified platform that can function solely or in integration with the existing healthcare solutions.</p>
                            </div>
                        </div>
                        <div className="dm-card">
                            <div>
                                <h5>EMR</h5>
                                <p>Our EMR solution will help you with Speciality Templates and custom templates to practice the EMR to store your patients’ health records digitally in a systematic way and to populate electronically whenever it is needed .....</p>
                            </div>
                        </div>
                    </Slider>
                    <div className="dm-btn-parent">
                        <button className="btn" onClick={previous}>
                            <GoArrowDownLeft color="#2362B6" />
                        </button>
                        <button className="btn" onClick={next}>
                            <GoArrowUpRight color="#2362B6" />
                        </button>
                    </div>
                </div>
            </section>
            <section className="who">
                <div className="container">
                    <h2>Make It Significant</h2>
                    <ul>
                        <li>Highly Scalable</li>
                        <li>Flexible Architecture</li>
                        <li>Seamless Data Integration</li>
                        <li>HIPAA Compliant</li>
                    </ul>
                </div>
            </section>
            <section className="benefits">
                <div className="container">
                    <h2>Benefits</h2>
                    <div className="grid_container_2">
                        <h3 className="grid-item">Clinical Decision Support</h3>
                        <h3 className="grid-item">Enhance Patient Care</h3>
                        <h3 className="grid-item">Real Time Information Exchange</h3>
                        <h3 className="grid-item">Data Analytics</h3>
                    </div>
                </div>
            </section>
            <section className="newproject">
                <div className="ellipse_top"></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default HealthcareIT;