import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { FaAnglesRight } from "react-icons/fa6";
import { Link, useNavigate ,useLocation} from 'react-router-dom';
const ProjectMaintenance = () => {
    const location = useLocation();
    const { sub_page,title,url } = location.state || {};
    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="Project Maintenance"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                    sub_page={sub_page ? {
                        'link': url,
                        'text': title
                    } : null}
                />
            </section>
            <section className="bggray_1">
                <div class="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="flex-item-erp">
                                <h2>Business Continuity</h2>
                                <p>Project maintenance is as important as project planning & a most overlooked factor in<br /> project success. We periodically assess the application to ensure its smooth functioning.</p>
                                <ul>
                                    <li><FaAnglesRight color="#2362B6" size={15} />Application Support</li>
                                    <li><FaAnglesRight color="#2362B6" size={15} />Operations Support</li>
                                    <li><FaAnglesRight color="#2362B6" size={15} />User Support</li>
                                    <li><FaAnglesRight color="#2362B6" size={15} />End User Support</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="image-right">
                                <img src={require('../../assets/images/services/Continity.png')} alt="Continity" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ecosystem-ao_project">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Services</h2>
                            <ul>
                                <li>Server Migration</li>
                                <li>Bug Fixes & Task Tracking</li>
                                <li>Upgrades & Enhancement</li>
                                <li>Application re-engineering</li>
                                <li>Hybrid App Integration Services</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <div className="image-right">
                                <img src={require('../../assets/images/services/Services.png')} alt="Services" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bggray_2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="image-right">
                                <img src={require('../../assets/images/services/whyChoose.png')} alt="whyChoose" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="flex_item">
                                <div>
                                    <h2>Why Choose Us</h2>
                                    <ul>
                                        <li><FaAnglesRight color="#2362B6" size={15} />100% Proactive maintenance</li>
                                        <li><FaAnglesRight color="#2362B6" size={15} />0% Downtime</li>
                                        <li><FaAnglesRight color="#2362B6" size={15} />5X Higher Efficiency</li>
                                        <li><FaAnglesRight color="#2362B6" size={15} />100% Customer Satisfaction</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="newproject">
                <div
                    className="ellipse_top"
                    style={{
                        background: `linear-gradient(
                                                  to right, 
                                                  #FFFFFF 10%, 
                                                  #b4cff3 100%
                                                          )`}}></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default ProjectMaintenance;