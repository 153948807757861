import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { Link } from "react-router-dom";

const ECommerce = () => {
    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="E Commerce"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                    sub_page={{
                        'link': '/services/business-domains',
                        'text': 'Business Domains'
                    }}
                />
            </section>
            <section className="right-sized">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="ecosystem-card padded ms-desktop">
                                <h5>Turn Shoppers Into Buyers</h5>
                                <p>We focus on providing a great virtual customer service and a seamless online experience. Enhance your growth with our multifaceted e commerce development solutions.</p>
                            </div>
                            <div className="ecosystem-card padded">
                                <h5>Sail Through It Smoothly</h5>
                                <p>Build, transform, and optimize. End goal of any business domain is to maximize profit while streamlining its operations. Be it a new one or updating an already existing e-shop we do it that too as per your requirements.</p>
                            </div>
                            <div className="ecosystem-card padded ms-desktop">
                                <h5>Bring Your Brand On Forefront</h5>
                                <p>Validate your ideas and build a performance driven design of your product. We have industry specific templates for all kinds of business. You can choose the template that works best for you.</p>
                            </div>
                        </div>
                        <div className="col-md-5 order-first">
                            <img src={require('../../assets/images/services/shoppers.webp')} alt="right sized" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <section class="banner-text">
                <div class="container">
                    <h4>Think Beyond Competition</h4>
                    <p>We merge innovation, content, creativity, and conversion to create master pieces that would make your e commerce stand apart from the competition in line with the industry trends.</p>
                </div>
            </section>
            <section className="our-services">
                <div className="container">
                    <h2>Here’s what our team has to offer</h2>
                    <div className="grid_container_2">
                        <div className="grid-item">
                            <span>01</span>
                            <div>
                                <h3>Custom Design</h3>
                                <p>We cater to customized website and mobile application development in both iOS and Android</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>02</span>
                            <div>
                                <h3>User Friendly Shopping Cart</h3>
                                <p>User friendly navigations prevent cart abandonment & make shopping experience a seamless one</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>03</span>
                            <div>
                                <h3>Secure Check Out Process</h3>
                                <p>Be it a single page checkout or specific scenario process we’ve got you covered.</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>04</span>
                            <div>
                                <h3>Product Catalogue Management</h3>
                                <p>Create product catalogue with flexible design and navigation aligned to your needs</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>05</span>
                            <div>
                                <h3>Order Management System</h3>
                                <p>Tailored to your business needs. Focus on growing your orders rather than managing them.</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>06</span>
                            <div>
                                <h3>CRM</h3>
                                <p>A goldmine that would help you determines potential sales opportunities.</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>07</span>
                            <div>
                                <h3>Social Media Integration</h3>
                                <p>Build brand awareness, communicate, and market your ecommerce business</p>
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>08</span>
                            <div>
                                <h3>Payment Gateway Integration</h3>
                                <p>Integrate any kind of payment method and let your customers choose their favourite one</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="newproject">
                <div className="ellipse_top" style={{ backgroundColor: 'white' }}></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default ECommerce;