import React from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import BreadCrumb from '../components/BreadCrumb';
import './About.css';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb active_page="About" />
            </section>
            <section className="about-us">
                <div className="container">
                    <h2 className="text-center">About Krisantec</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Technology To Innovation</h2>
                            <p>
                                The future of innovations depends solely on its technological foundations. We focus on innovation and excellence through the adoption of best practices and this is what gives us a competitive niche. We believe it’s just not the idea but execution that makes the ideas happen. Our delivery model involves strategic approaches that align with organization priorities, market dynamics and evolving technologies.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img src={require('../assets/images/about/innovation.png')} alt="innovation" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="customer-centric">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={require('../assets/images/about/centric.png')} alt="innovation" />
                        </div>
                        <div className="col-md-6">
                            <h2>Customer Centric</h2>
                            <p>
                                We don’t fit in a technology to fix a business solution. Personalization is the heart of customer centricity. We focus on delivering client centric innovation and value instead of one size fits all solutions
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="process">
                <h2>Process We Follow</h2>
                <div className="container">
                    <div className="grid_container_4">
                        <div className="grid-item">Strategy</div>
                        <div className="grid-item">Prototyping</div>
                        <div className="grid-item">Development</div>
                        <div className="grid-item">Deployment</div>
                    </div>
                    <div className="grid_container_3">
                        <div className="grid-item">Application Testing</div>
                        <div className="grid-item">Performance Optimization</div>
                        <div className="grid-item">Support & Maintenance</div>
                    </div>
                </div>
            </section>
            <section className="timebox">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Time Box Every Element</h2>
                            <p>
                                We prioritize tasks at a fast pace, divide our responsibilities efficiently and revise our processes through sprints and iterations. This prevents product backlogs with consistent efficiency.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img src={require('../assets/images/about/timebox.png')} alt="innovation" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="diversity">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={require('../assets/images/about/diversity.png')} alt="innovation" />
                        </div>
                        <div className="col-md-6">
                            <h2>Diversity & Inclusion</h2>
                            <p>
                                Our Associates are our brand ambassadors. We believe that diversity that make us even stronger and the diverse background of our Associates adds to the plethora of our point of views.We are a blend of Strategists, Architects, Business Analysts, Designers, Developers & Quality Analyst and this helps us discover best possible methods to provide solutions those fulfils the business requirements of our clients.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-choose">
                <h2>Why Choose Us?</h2>
                <img src={require('../assets/images/about/whychoose.webp')} />
            </section>
            <section className="newproject">
                <div className="ellipse_top"></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default About;