import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';

const SearchEngineMarketing = () => {
    return (
        <div>
            <Navbar />
                <div class="container">
                    <BreadCrumb 
                        active_page="Search Engine Marketing" 
                        prev_sub_page={{
                            'link':'/services',
                            'text':'Services'
                        }}
                        sub_page={{
                            'link':'/services/digital-marketing',
                            'text':'Digital Marketing'
                        }} 
                    />
                    <div class="inner-container">
                        <h2>Search Engine Marketing</h2>
                        <h3>Out Shine Your Competition</h3>
                        <p>Improve your digital presence and secure your brand recognition. We ensure search engines find your site and make it rank high in search engine page results. We take care of everything from crawling, indexing, and ranking of web pages on search engines.</p>
                        <h3>Reach Users At Every Stage Of Buying Cycle</h3>
                        <p>It ‘s all about creating right search engine marketing strategy and working continuously on advertising strategies based on analytics</p>
                        <h3 className="text-center">Our Systematic Approach</h3>
                        <img alt="" src={require('../../assets/images/services/sem.png')} className="d-block m-auto mw-50" />
                        <h3>Keyword Research:</h3>
                        <p>Our team would analyze the high performing search terms using keyword research tools and use it to create ads</p>
                        <h3>Craft Ads</h3>
                        <p>Once we have the perfectly researched blend of keywords we would use them to craft text based ads to display them accordingly in search engine results while also linking and optimizing them with your landing pages.</p>
                        <h3>Campaign Launch</h3>
                        <p>The campaign is launched using a robust search engine marketing strategy and is closely monitored. You can choose the type of PPC search engine marketing campaign.</p>
                        <h3>Evaluation</h3>
                        <p>Evaluation includes measuring the key performance indicators (KPI) including CTR, quality score, search impression share, and cost per conversion to check the ad performance</p>
                        <h3>Benefits</h3>
                        <ul>
                            <li>Better Brand Recognition</li>
                            <li>Conversions</li>
                            <li>Specific Targeting</li>
                            <li>Campaign Control</li>
                            <li>Measurable Growth</li>
                        </ul>
                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default SearchEngineMarketing;