import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';

const SocialMediaMarketing = () => {
    return (
        <div>
            <Navbar />
                <div class="container">
                    <BreadCrumb 
                        active_page="Social Media Marketing" 
                        prev_sub_page={{
                            'link':'/services',
                            'text':'Services'
                        }}
                        sub_page={{
                            'link':'/services/digital-marketing',
                            'text':'Digital Marketing'
                        }} 
                    />
                    <div class="inner-container">
                        <h2>Social Media Marketing</h2>
                        <h3>Reach out</h3>
                        <p>Leverage the power of social media networks to achieve branding goals. Stay connected with your audience and build a community around your brand</p>
                        <img alt="" src={require('../../assets/images/services/smm.PNG')} className="d-block m-auto mw-50" />
                        <h3>Drive Results</h3>
                        <p>From curating a social media strategy, to executing campaigns, and measuring results we help you make the most out of social media marketing. Our social media strategies are tailored to your needs and objectives.</p>
                        <h3>It’s All About Influence</h3>
                        <p>Building an interaction between your brand and the user is what matters the most. It is seen that a positive social media experience is inclined with an increase in conversion rates.</p>
                        <h3 className="text-center">Our Marketing Strategy</h3>
                        <img alt="" src={require('../../assets/images/services/smm-2.png')} className="d-block m-auto mw-50" />
                        <h3>Here’s What Included In Our Social Media Marketing Services</h3>
                        <ul>
                            <li>Social media audit</li>
                            <li>Social Media Contests</li>
                            <li>Paid Social Ads</li>
                            <li>Campaigns</li>
                            <li>Social Selling</li>
                        </ul>
                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default SocialMediaMarketing;