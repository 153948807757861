import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { Link, useNavigate,useLocation } from 'react-router-dom';
const ERPDevelopment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { sub_page,title,url } = location.state || {};
    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="ERP Development"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                    sub_page={sub_page ? {
                        'link': url,
                        'text': title
                    } : null}
                />
             </section>
               <section className="bggray">
                <div class="container">
                    <div className="row">
                     <div className="col-md-8">
                        <div className="flex-item-erp">
                          <h3>Make Your Enterprise Future Ready</h3>
                          <p>Streamline your business operations, monitor financial activities and  mould the date into actionable insights. We capitalize on the latest technology stacks and blend it well with the Domain and Functional expertise of our teams.</p>
                          </div>
                     </div>
                     <div className="col-md-4">
                      <div className="image-right">
                      <img src={require('../../assets/images/services/Enterprise.png')} alt="Enterprise" />
                      </div>
                     </div>
                    </div>
                </div>
                </section>
                <section className="Control">
                <h4>Take Control Of Your Business</h4>
                <div class="container">
                    <div className="grid_container_flex">
                        <div className="grid-item">Sales</div>
                        <div className="grid-item">Inventory Management</div>
                        <div className="grid-item">Purchase Order
                        Management </div>
                    </div>
                    <div className="grid_container_flex">
                        <div className="grid-item">Billing & Finance</div>
                        <div className="grid-item">Customer Relationship 
                        Management</div>
                        <div className="grid-item">Human Resources</div>
                    </div>
                    <div className="grid_container_flex_2">
                        <div className="grid-item">Asset Management </div>
                        <div className="grid-item">Reporting & Analytics</div>
                       
                    </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                <img alt="" src={require('../../assets/images/services/erp.png')} width="100%"/>
                </div>
                <div className="ERP_Adoption">
                <h4>ERP Adoption Phases</h4>
                    <div className="container">
                        <div className="flex-item-container">
                        <div className="card">
                        <div className="card-daetail">
                             <h5>Preparation</h5>
                             <p>Includes consulting, identification of project scope, organizational strategies, existing business operations to fit with your specific business needs.</p>
                            </div>
                         <div className="card-no">
                           01
                         </div>
                        </div>
                        <div className="card">
                        <div className="card-daetail">
                             <h5>Configuration</h5>
                             <p>We offer customizations & this feature is what would give your ERP a competitive niche over other organizations that would use a standardized ERP functionality.</p>
                            </div>
                        <div className="card-no">
                           02
                         </div>
                        </div>
                        <div className="card">
                        <div className="card-daetail">
                             <h5>Customization</h5>
                             <p>Includes consulting, identification of project scope, organizational strategies, existing business operations to fit with your specific business needs.</p>
                            </div>
                        <div className="card-no">
                           03
                         </div>
                        </div>
                        <div className="card">
                        <div className="card-daetail">
                             <h5>Extensions & Data Migration</h5>
                             <p>You can seamlessly transfer data from its primary sources into the newly adopted ERP systems. We thoroughly analyse every section of the data. This is followed by mapping the information to its location in the ERP database and data migration.</p>
                            </div>
                        <div className="card-no">
                           04
                         </div>
                        </div>
                        <div className="card">
                        <div className="card-daetail">
                             <h5>User Training</h5>
                             <p>We design most user friendly UX/UI designs to make sure end user adopt it to most convenient and we extend end user trainings through user training manuals and visual interactive material</p>
                            </div>
                        <div className="card-no">
                           05
                         </div>
                        </div>
                        <div className="card">
                        <div className="card-daetail">
                             <h5>Support</h5>
                             <p>We offer continuous support and provide you with maintenance services that would keep your ERP system functioning without any glitches.</p>
                            </div>
                        <div className="card-no">
                           06
                         </div>
                        </div>
                        </div>
                </div>
                </div>
                </section>
                <section className="Business">
                <h4>Add Value To Your Business</h4>
                <div class="container">
                    <div className="grid_container_flex">
                        <div className="grid-item">Cyber security</div>
                        <div className="grid-item">Automation</div>
                        <div className="grid-item">Data Centralization </div>
                    </div>
                    <div className="grid_container_flex">
                        <div className="grid-item">Regulatory Compliances</div>
                        <div className="grid-item">Social Media Integration</div>
                        <div className="grid-item">Scalability</div>
                    </div>
                    </div>
                </section>
                <section className="newproject">
                <div className="ellipse_top"></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default ERPDevelopment;