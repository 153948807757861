import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { FaAnglesRight } from "react-icons/fa6";
import { Link, useNavigate,useLocation } from 'react-router-dom';
const ITConsulting = () => {
    const location = useLocation();
    const { sub_page,title,url } = location.state || {};
    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="IT Consulting"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                    sub_page={sub_page ? {
                        'link': url,
                        'text': title
                    } : null}
                />
            </section>
            <section className="application">
                <div className="container">
                    <div>
                        <h2>Get Your Business Aligned</h2>
                        <p>We help you digitalize operations, optimize portfolios, and implement the latest technologies. Our team of strategists, enterprise architects, and transformation experts would harness technology to reinvent business of any kind.</p>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <h4>IT Strategy Consulting</h4>
                            <ul>
                                    <li><FaAnglesRight color="#2362B6" size={15} />Outline strategies that address business needs & IT resources</li>
                                    <li><FaAnglesRight color="#2362B6" size={15} />Align IT strategy with business strategy</li>
                                    <li><FaAnglesRight color="#2362B6" size={15} />Enhance architectural design</li>
                                    <li><FaAnglesRight color="#2362B6" size={15} />Strategic initiatives for development, modernization, & migration</li>
                                </ul>
                        </div>
                        <div className="col-md-6">
                            <div className="image-right" style={{maxWidth:"596px"}}>
                                <img src={require('../../assets/images/services/ItConsulting.png')} alt="ItConsulting" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="Control">
                <div className="container">
                   <h2 className="text-center" style={{marginBottom:"20px"}}>IT Assessment</h2>
                   <p className="text-center">Gain a deeper understanding of your environment. To enhance efficiency, security, and compliance of IT infrastructure</p>
                </div>
            </section>
            <section className="Business" style={{background:"none"}}>
                <h4>We assess</h4>
                <div class="container">
                    <div className="grid_container_flex">
                        <div className="grid-item">Opportunities for cost
                        optimization</div>
                        <div className="grid-item">Network security</div>
                        <div className="grid-item">Business applications</div>
                    </div>
                    <div className="grid_container_flex">
                        <div className="grid-item">Server infrastructure</div>
                        <div className="grid-item">Data Storage</div>
                        <div className="grid-item">Cloud services</div>
                    </div>
                    </div>
                </section>
                <section className="Opertions">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="image-right">
                                <img src={require('../../assets/images/services/It_consulting_1.png')} alt="it_consulting_1" />
                            </div>
                        </div>
                        <div className="col-md-6" >
                            <div className="text-center-container">
                            <h2>IT Operations Consulting</h2>
                            <ul className="text-cetner">
                                    <li><FaAnglesRight color="#FFF" size={15} />Connect boardroom strategies to the frontline</li>
                                    <li><FaAnglesRight color="#FFF" size={15} />Address how processes can be refined</li>
                                    <li><FaAnglesRight color="#FFF" size={15} />Identify glitches in workflows</li>
                                    <li><FaAnglesRight color="#FFF" size={15} />Uplift sales operation by restructuring client engagement models</li>
                                </ul>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="Opertions" style={{background:"none",color:"#000"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="text-center-container">
                            <h2>Technology Consulting</h2>
                            <ul className="text-cetner">
                                    <li><FaAnglesRight color="#2362B6" size={15} />Planning roadmap for technology adoption</li>
                                    <li><FaAnglesRight color="#2362B6" size={15} />Assist choosing right technology in line with your industry</li>
                                    <li><FaAnglesRight color="#2362B6" size={15} />Risk assessment analysis</li>
                                    <li><FaAnglesRight color="#2362B6" size={15} />Integrate cutting edge technology tools</li>
                                    <li><FaAnglesRight color="#2362B6" size={15} />Risk assessment analysis</li>
                                    <li><FaAnglesRight color="#2362B6" size={15} />Security consulting</li>
                                    <li><FaAnglesRight color="#2362B6" size={15} />Recovery plan</li>
                                </ul>
                                </div>
                        </div>
                        <div className="col-md-6" >
                                <div className="image-right">
                                <img src={require('../../assets/images/services/It_consulting_2.png')} alt="it_consulting_1" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="Business">
                <h4>Application Support</h4>
                <div class="container">
                    <div className="grid_container_flex">
                        <div className="grid-item">Pro Active Monitoring</div>
                        <div className="grid-item">Security Testing</div>
                        <div className="grid-item">Performance Modeling</div>
                    </div>
                    <div className="grid_container_flex">
                        <div className="grid-item">Configuration Management</div>
                        <div className="grid-item">Bug Analysis</div>
                        <div className="grid-item">Plan application 
                        modernization activities</div>
                    </div>
                    </div>
                </section>
                <section className="newproject">
                <div className="ellipse_top"></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default ITConsulting;