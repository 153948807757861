import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import BreadCrumb from "../../components/BreadCrumb";
import {Link} from "react-router-dom";

const ERPAutomation = () => {
  return (
    <div>
      <Navbar />
      <section className="about-breadcrumb">
        <BreadCrumb
          active_page="Real Estate"
          prev_sub_page={{
            link: "/services",
            text: "Services",
          }}
          sub_page={{
            link: "/services/business-process",
            text: "Business Process",
          }}
        />
      </section>
      <section className="right-sized">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div
                className="ecosystem-card"
                style={{ padding: "30px 56px", maxWidth: "542px" }}
              >
                <h5>ERP Automation</h5>
                <p>
                  Expand globally, run operations with ease using our integrated
                  unified platform that seamlessly automates complex process of
                  your enterprise.
                </p>
              </div>
              <div
                className="ecosystem-card"
                style={{ padding: "30px 56px", maxWidth: "542px" }}
              >
                <h5>Negate Need For Spreadsheets </h5>
                <p>
                  An all in one platform that would automate any aspect of your
                  business process be it finance, inventory, supply chain,
                  sales, purchasing, human resources, customer relationship
                  management, & so on.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src={require("../../assets/images/services/ERP.webp")}
                alt="right sized"
                className="erp"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="banner-text how">
        <div className="container">
          <h2>How We Do</h2>
          <div className="grid_container_3">
            <h3 className="grid-item">Identify Goals</h3>
            <h3 className="grid-item">Build A Strong Foundation</h3>
            <h3 className="grid-item">Formalize ChangeManagement</h3>
          </div>
          <div className="grid_container_2">
            <h3 className="grid-item">Optimize ERp Automation</h3>
            <h3 className="grid-item">Continuous Improvement </h3>
          </div>
        </div>
      </section>
      <section className="erp-automation">
        <div className="container">
        <h2>Get The Most Of ERP Automation</h2>
          <div className="row">
          <div className="col-md-2 erp-card">
            <div className="list-items">
            <h3>Financial Management </h3>
                <ul className="list-with-lines">
                <li className="list-item">Budgets</li>
                <li className="list-item">Account Receivables</li>
                <li className="list-item">Account Payables </li>
                <li className="list-item">Account Schedules</li>
                </ul>
            </div>
          </div>
          <div className="col-md-2 erp-card">
          <div className="list-items">
          <h3>Inventory Management</h3>
                <ul className="list-with-lines">
                <li className="list-item">Inventory</li>
                <li className="list-item">Item Substitutions</li>
                <li className="list-item">Returns</li>
                <li className="list-item">Cancellations</li>
                </ul>
            </div>
            </div><div className="col-md-2 erp-card">
            <div className="list-items">
            <h3>Sales</h3>
                <ul className="list-with-lines">
                <li className="list-item">Sales Invoicing</li>
                <li className="list-item">Sales Order</li>
                <li className="list-item">Pricing & Discounting</li>
                <li className="list-item">Contact Management</li>
                <li className="list-item">Opportunity Management</li>
                </ul>
                </div>
            
          </div><div className="col-md-2 erp-card">
          <div className="list-items">
            <h3>Purchasing</h3>
            <ul className="list-with-lines">
                <li className="list-item">Purchase Order Management</li>
                <li className="list-item">Vendor Management</li>
                <li className="list-item">Vendor Item Catalog</li>
               </ul>
               </div>
            
          </div><div className="col-md-2 erp-card">
          <div className="list-items">
            <h3>Human Resources     
            </h3>
            <ul className="list-with-lines">
                <li className="list-item">Recruitment</li>
                <li className="list-item">Onboarding</li>
                <li className="list-item">Leave Management</li>
                </ul>
                </div>
            
          </div><div className="col-md-2 erp-card">
          <div className="list-items">
            <h3>Additional</h3>
            <ul className="list-with-lines">
                <li className="list-item">Multiple Currencies</li>
                <li className="list-item">Dimensions</li>
                <li className="list-item">Document Reporting</li>
                </ul>
            </div>
            </div>
              </div>
              </div>
      </section>
      <section className="virtual" style={{backgroundColor: '#E6EEFB'}}>
                <div className="container">
                    <h2>Engine Of Enterprise</h2>
                    <div className="row mt-5">
                        <div className="col-md-6">
                        <img src={require('../../assets/images/services/DoubleImage (2).webp')} alt="double image" className="virtual-image" />
                        </div>
                        <div className="col-md-6 align-desktop-center">
                                <ul className="blue-arrows">
                                <li>Simplified Operations</li>
                                <li>Lower Costs</li>
                                <li>Go Paperless</li>
                                <li>Employee Coordination</li>
                                <li>Reduced Risks</li>
                                <li>Product Safety</li>
                                <li>Improve Predicatability </li>
                                <li>Superior Quality</li>
                                <li>Enhanced Process
                                Consistency</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="newproject">
                <div className="ellipse_top"></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Need a Successful<br/>Your Project?</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
      <Footer />
    </div>
  );
};

export default ERPAutomation;
