import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';

const EmailMarketing = () => {
    return (
        <div>
            <Navbar />
                <div class="container">
                    <BreadCrumb 
                        active_page="Email Marketing"
                        prev_sub_page={{
                            'link':'/services',
                            'text':'Services'
                        }}
                        sub_page={{
                            'link':'/services/digital-marketing',
                            'text':'Digital Marketing'
                        }} 
                    />
                    <div class="inner-container">
                        <h2>Email Marketing</h2>
                        <h3>Cement Your Brand Identity</h3>
                        <p>We help you plan, build, execute all kinds of email marketing campaigns i.e., newsletters, product, promotional, and transactional. Tailored marketing campaigns for targeted audience which would keep them in line with latest products/services</p>
                        <h3>Roadmap - Email Marketing Process</h3>
                        <img alt="" src={require('../../assets/images/services/email-marketing.png')} className="d-block m-auto mw-80" />
                        <h3>Drip Marketing</h3>
                        <p>We employ drip marketing software to send series of automated emails based on specific timeframes and user actions.</p>
                        <p>This could be in the form of brand updates, product teasers, deals, and offers</p>
                        <h3>Do It Right With Us</h3>
                        <ul>
                            <li>Account Set Up</li>
                            <li>Copy Writing</li>
                            <li>Marketing Collaterals</li>
                            <li>Integration With Website</li>
                            <li>Email Blasts</li>
                            <li>Optimization</li>
                        </ul>
                        <h3>We Don’t Just Help You Execute</h3>
                        <ul>
                            <li>Build Credibility</li>
                            <li>Connect With Differential Users</li>
                            <li>Smart Segmentation</li>
                            <li>Send Time Optimization</li>
                        </ul>
                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default EmailMarketing;