import React, { useState, useEffect, useRef, useCallback } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Link, useNavigate, useSearchParams, useLocation, useParams } from "react-router-dom";
import './Blog.css';
import { AiOutlineLike } from "react-icons/ai";
import { PiShareFat } from "react-icons/pi";
import BlogItem from "./BlogItem";
import axiosConfig from "../../Service/axiosConfig";
import useDebouncedValue from "./DeBounce";
const Blog = ({category}) => {
    const [blogCategories, setBlogCategories] = useState([]);
    const [blogItems, setBlogItems] = useState([]);
    const [moreTopics, setMoreTopics] = useState(false);
    const [search, setSearch] = useState("");
    const debouncedSearchTerm = useDebouncedValue(search, 500);
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const [page, setPage] = useState(1); // Track current page
    const [next, setNext] = useState(null);
    const navigate = useNavigate();
    const params = useParams();
    const listRef = useRef();

    const GetData = async (page) => {
        try {
            const blog_response = await axiosConfig.get(`/blogapp/blog/?page=${page}&page_size=2&category=${category ? category : ''}&search=${search}`)
            setNext(blog_response.data.next)
            if (page === 1) {
                setBlogItems(blog_response.data.results);
            } else {
                setBlogItems((prevItems) => [...prevItems, ...blog_response.data.results]);
            }
           
        } catch (error) {
            console.log("Error getting blog data :", error);
        }
    };

    const GetBlogData = async () => {
        try {
            const categories_response = await axiosConfig.get(`/blogapp/blogcategory/?page_size=20`);
            setBlogCategories(categories_response.data.results);
        } catch (error) {
            console.log("Error getting blog data :", error);
        }
    };

    const handleClick = (url) => {
        navigate(url);
    };

    const handleCategoryClick = (id, title) => {
        setPage(1)
        navigate(`/blog/${title}`);
    };

    useEffect(() => {
        GetData(page);
    }, [page,category, debouncedSearchTerm]);

    useEffect(() => {
        if (!search) {
            GetBlogData();
        }
    }, [search]);

    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = listRef.current;
                if (scrollTop + clientHeight >= scrollHeight - 5) { // Adding some buffer
                    loadMoreItems();
                }
            }
        };

        listRef.current.addEventListener('scroll', handleScroll);
        return () => {
            if (listRef.current) {
                listRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [listRef, page]);

    const loadMoreItems = () => {
        // Call your API here
        console.log('Bottom reached, loading more items...');
        // Example: loadMoreBlogItems(page + 1);
        setPage(prevPage => prevPage + 1);
    };

    return (
        <>
            <Navbar />
            <div className="container">
                <div className="blog-grid">
                    <div className="list" ref={listRef} style={{ overflowY: 'scroll', height: '80vh', scrollbarWidth: 'none', msOverflowStyle: 'none',width:"100%" }}>
                        <ul>
                            {blogCategories.length > 0 &&
                                blogCategories.map((item) => (
                                    <li
                                        style={params?.seo_url === item.slug ? { color: 'red' } : null}
                                        onClick={() => handleCategoryClick(item.id, item.slug)}
                                        key={item.id}
                                        className="pointer"
                                    >
                                        {item.title}
                                    </li>
                                ))
                            }
                        </ul>
                        {blogItems.length > 0 &&
                            blogItems.map((item) => (
                                <BlogItem item={item} params={params?.seo_url} category={category} key={item.id} />
                            ))
                        }
                    </div>
                    <div className="more">
                        <div>
                            <div className="form-group my-4">
                                <label htmlFor="name">Blog Search:</label>
                                <input type="text" className="form-control" placeholder="Blog Search" onChange={(e) =>{
                                     setSearch(e.target.value)
                                     setPage(1)
                                }} />
                            </div>
                            <h5>Recommended topics</h5>
                            <div className="filters">
                                {blogCategories.length > 0 &&
                                    blogCategories.map((item) => (
                                        <span
                                            className="topic"
                                            key={item.id}
                                            onClick={() => handleCategoryClick(item.id, item.slug)}
                                        >
                                            {item.title}
                                        </span>
                                    ))
                                }
                            </div>
                            {/* <span className="see-more">See more topics</span>
                            <div className="topics">
                                <div onClick={() => handleClick('/about')}>About</div>
                                <div onClick={() => handleClick('/services')}>Services</div>
                                <div onClick={() => handleClick('/')}>Careers</div>
                                <div onClick={() => handleClick('/blog')}>Blog</div>
                                <div onClick={() => handleClick('/')}>Terms</div>
                                <div onClick={() => handleClick('/contact-us')}>Contact</div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <section className="newproject">
                <div className="ellipse_top" style={{ backgroundColor: 'white' }}></div>
                <div className="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </>
    );
}

export default Blog;
