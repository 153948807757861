import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';

const BillingAccountsProcessAutomation = () => {
    return (
        <div>
            <Navbar />
                <div class="container">
                    <BreadCrumb 
                        active_page="Billing & Accounts Process Automation"
                        prev_sub_page={{
                            'link':'/services',
                            'text':'Services'
                        }} 
                        sub_page={{
                            'link':'/services/business-process',
                            'text':'Business Process'
                        }} 
                    />
                    <div class="inner-container">
                        <h2>Billing & Accounts Process Automation</h2>
                        <h3>Get Paid Sooner</h3>
                        <p>Don’t allow your current manual process to increase the risk of financial leaks. Centralise your billing and payment process from invoice creation to payment collection and get paid faster.</p>
                        <h3>Make A Strategic Move</h3>
                        <p>Automation makes it easier to track the organisational financial performance and enables financial planning.</p>
                        <h3>How We Do</h3>
                        <img alt="" src={require('../../assets/images/services/bapa.png')} className="d-block m-auto mw-60" />
                        <h3>How Automation Can Help Your Business</h3>
                        <h3>Invoicing</h3>
                        <ul>
                            <li>Create Invoices</li>
                            <li>Reconcile Data & Accounts</li>
                            <li>Streamline Data Entry & Retrieval</li>
                            <li>Generate Reports In Real Time</li>
                        </ul>
                        <h3>Procurement</h3>
                        <ul>
                            <li>Automate onboarding of new suppliers</li>
                            <li>Generate Work Orders</li>
                            <li>Track Shipments</li>
                            <li>Handle Customer Enquiries</li>
                            <li>Stock Reminder Alerts</li>
                        </ul>
                        <h3>Customer Relations</h3>
                        <ul>
                            <li>Resolve Account Discrepancies</li>
                            <li>Payment Enquiries</li>
                            <li>Account Reconciliations</li>
                        </ul>
                        <h3>Financial Services</h3>
                        <ul>
                            <li>Robust Forecasting</li>
                            <li>Muti Currency Payments</li>
                            <li>Co Payments</li>
                            <li>Payment Reminders</li>
                        </ul>
                        <h3>Restructure Your Way Of Business</h3>
                        <p>Paperless Processing</p>
                        <p>Unified Console</p>
                        <p>Service Portals</p>
                        <p>Better Compliance</p>
                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default BillingAccountsProcessAutomation;