import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import './BusinessDomains.css';
import { GoArrowUpRight } from "react-icons/go";
import { useNavigate, Link } from "react-router-dom";
import healthImage from '../../assets/images/services/health.webp';
import itImage from '../../assets/images/services/it (2).png';
import ecommImage from '../../assets/images/services/ecomm.png';
import elearnImage from '../../assets/images/services/elearn.png';
import retailImage from '../../assets/images/services/retail (2).png';

const BusinessDomains = () => {
    const navigate = useNavigate();
    const handleClick = (url) => {
        navigate(url);
    }

    const mapData = [
        {
            serialNo: "01",
            heading: "Healthcare IT",
            imagePath: healthImage,
            navigation: "healthcare-it",
            content: <p>We build our solutions around your practice. Our solutions would cover every aspect of your practice, be it diagnostics, patient care, or administration. Unify all roles of your practise to improve clinical care.</p>
        },
        {
            serialNo: "02",
            heading: "Information Technology",
            imagePath: itImage,
            navigation: "information-technology",
            content: <p>It’s your idea and we help you do more of it. We speak your language and turn your ideas into ground breaking business solutions.</p>
        },
        {
            serialNo: "03",
            heading: "E Commerce",
            imagePath: ecommImage,
            navigation: "e-commerce",
            content: <p>Build, transform, and optimize. End goal of any business domain is to maximize profit while streamlining its operations. Be it a new one or updating an already existing e-shop we do it that too as per your requirements.</p>
        },
        {
            serialNo: "04",
            heading: "eLearning",
            imagePath: elearnImage,
            navigation: "elearning",
            content:
                <>
                    <p>Solutions should not only solve problems but add value to the environment in which the solution is offered.</p>
                    <p>We just don’t create eLearning tools, but offer you an incredible learning experience. Our eLearning solutions involves a classroom blended virtual delivery mode of approach</p>
                </>
        },
        {
            serialNo: "05",
            heading: "Retail Software System",
            imagePath: retailImage,
            navigation: "retail-software-system",
            content: <p>Our retail management software would help you sell in store, online, at marketplaces, and on the go. You can manage all aspects of your business with this easy to use system. Add warehouses, outlets, new locations at the click of the button.</p>
        }
    ]

    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="Business Domains"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                />
            </section>
            <div className="domain-parent">
                <div className="container">
                    {mapData.map((item) => (
                        <div className="domain-card">
                            <div className="rowBetweenCenter flex_1">
                                <div className="d-flex align-items-center">
                                    <div className="index">{item.serialNo}</div>
                                    <h3 className="h_32_500">{item.heading}</h3>
                                </div>
                                <div className="img-container">
                                    <img src={item.imagePath} alt="domain-image" />
                                    <div className="overlay">{item.content}</div>
                                </div>
                            </div>
                            <div className="arrow" onClick={() => handleClick(item.navigation)}>
                                <GoArrowUpRight size={30} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <section className="newproject">
                <div className="ellipse_top" style={{ backgroundColor: 'white' }}></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default BusinessDomains;