import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';

const SupplyChainProcess = () => {
    return (
        <div>
            <Navbar />
                <div class="container">
                    <BreadCrumb 
                        active_page="Supply Chain Process" 
                        prev_sub_page={{
                            'link':'/services',
                            'text':'Services'
                        }}
                        sub_page={{
                            'link':'/services/business-process',
                            'text':'Business Process'
                        }} 
                    />
                    <div class="inner-container">
                        <h2>Supply Chain Process</h2>
                        <h3>Plan That Fits You</h3>
                        <p>We tailor your supply chain strategy to meet your needs. Optimize procurement management, enhance process transparency while saving on time and resources.</p>
                        <h3>Our Approach</h3>
                        <h3>Research</h3>
                        <p>We research to understand your business and check whether it is aligned with the market trends</p>
                        <h3>Observe</h3>
                        <p>This step involves unearthing the bottlenecks affecting your supply chain process</p>
                        <h3>Recommend</h3>
                        <p>Based on analysis we would suggest some recommendations that would allow you to make informed decisions.</p>
                        <h3>Identify</h3>
                        <p>We identify and prioritize our initiatives for optimization of the solution</p>
                        <h3>Create</h3>
                        <p>This is the final step and involves creation of the roadmap for development of the solution</p>
                        <h3>Our Services</h3>
                        <img alt="" src={require('../../assets/images/services/scp.png')} width="100%"/>
                        <h3>Benefits</h3>
                        <ul>
                            <li>Quality Control</li>
                            <li>Reduced Overhead Costs</li>
                            <li>Inventory Tracking</li>
                            <li>Order Management</li>
                            <li>Reverse Logistics</li>
                            <li>Sales Forecasting</li>
                        </ul>
                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default SupplyChainProcess;