import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';

const SalesProcessAutomation = () => {
    return (
        <div>
            <Navbar />
                <div class="container">
                    <BreadCrumb 
                        active_page="Sales Process Automation" 
                        prev_sub_page={{
                            'link':'/services',
                            'text':'Services'
                        }}
                        sub_page={{
                            'link':'/services/business-process',
                            'text':'Business Process'
                        }} 
                    />
                    <div class="inner-container">
                        <h2>Sales Process Automation</h2>
                        
                        <h3>Strike The Right Balance</h3>
                        <p>Eliminate manual tedious tasks, streamline recurring process, and improves your sales force efficiency by automating tasks, workflows, processes, customer journey, campaigns, & so on.</p>
                        <p>Manage all your products, processes, and teams from a single unified platform</p>
                        <h3>Well Take Care Of Your Trivial Tasks</h3>
                        <p>While letting your team focus on more on important activities like building customer relationships, nurturing leads that require attention, understanding data patterns, and helps you market smarter.</p>
                        <h3>Sales Contact Management</h3>
                        <img alt="" src={require('../../assets/images/services/spa.png')} className="d-block m-auto mw-70" />
                        <h3>Choose One That Fits Your Needs</h3>
                        <img alt="" src={require('../../assets/images/services/spa-2.png')} className="d-block m-auto mw-70" />
                        <h3>Industries We Focus On</h3>
                        <ul>
                            <li>IT</li>
                            <li>BPO</li>
                            <li>Insurance</li>
                            <li>Retail</li>
                            <li>E Commerce</li>
                            <li>Shipping</li>
                        </ul>
                        <h3>Make Mindful Decisions</h3>
                        <ul>
                            <li>Personalize campaigns</li>
                            <li>Prequalify Leads</li>
                            <li>Automated scheduling</li>
                            <li>Manage multiple pipelines</li>
                            <li>Turn Insights Into Actions</li>
                            <li>Shorter Sales Cycle</li>
                        </ul>


                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default SalesProcessAutomation;